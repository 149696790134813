export const API_URL = process.env.REACT_APP_BASE_API_URL || "https://api.reachifyme.com/monolith";
export const CDN_URL = process.env.REACT_APP_BASE_CDN_URL || "https://prd-cdn.reachifyme.com/monolith";
export const SearchPlaceHolderArray = [
  "Add any idea to generate post",
  "Common mistakes people make as a freelancer",
  "How coaches can help you improve your career",
  "Project with my client gave them 10x revenue",
];

export const generateFromYoutube = [
  "Add any youtube video link.",
  "https://www.youtube.com/watch?v=DxIDKZHW3-E",
];
export const generateFromArticle = [
  "website page/blog, substack, medium, News articles,etc. Any public link.",
  "https://reachifyme.com/blogs/how-to-promote-yourself-on-linkedin",
  "https://tahahussain.substack.com/p/how-to-survive-and-thrive-in-a-toxic",
  "https://towardsdatascience.com/whats-inside-a-neural-network-799daf235463",
  "https://www.thehindu.com/opinion/editorial/%E2%80%8Bdemand-flux-on-growth-dynamics/article68698054.ece",
];
export const RE_CAPTCHA_CLIENT_KEY = "6LcTgEEpAAAAAIZ652nccO7mp-NHVQzaBqqx9umk";
export const LTDPlanIds = ["SP2024R11t24FNLhlW", "SP2024M11u24SgP7fA"];
export const FreeTrailPlanGroupId = "FREE_TRIAL";
export const demoCallBookingUrl="https://calendly.com/devesh-reachifyme/30min";
